import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { Container } from '@material-ui/core';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import Ribbon from '../../components/Ribbon';
import CardWide from '../../components/Cards/CardWide';
import Maze from '../../components/Maze';
import { ventilatieData } from '../../lib/overviewData';

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "ventilatie/ventilatie-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(relativePath: { eq: "ventilatie/ventilatie-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gridImages1: file(relativePath: { eq: "ventilatie/ventilatie.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gridImages2: file(relativePath: { eq: "ventilatie/ventilatie-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gridImages3: file(relativePath: { eq: "ventilatie/ventilatie-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const Ventilatie = ({ data, path, location }) => {
  const seo = {
    title: `E S I - alle ventilatie producten`,
    description: `E S I - alle ventilatie producten die E S I aanbiedt om u te helpen.`,
    pathname: path,
    location,
  };

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const GridImages = [data.gridImages1, data.gridImages2, data.gridImages3];

  const ribbon = {
    image: sources,
    heading: `E S I – Ventilatie overzicht`,
    pitchLine: `Alle ventilatie disciplines onder een dak.`,
  };

  const textTop = {
    header: `E S I biedt u alle ventilatie mogelijkheden aan voor een optimaal binnenklimaat`,
    textWithLink: () => (
      <>
        <p>
          Wij denken met u mee aan de optimale oplossing voor uw binnenklimaat,
          denk bijvoorbeeld in de vorm van een mechanische ventilatie-, wtw-, of
          airconditioningsinstallatie.
        </p>
      </>
    ),
  };

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        location={location}
      />
      <Ribbon
        fluid={ribbon.image}
        heading={ribbon.heading}
        pitchLine={ribbon.pitchLine}
      />
      <Container fixed className='mt-10x mb-10x'>
        <CardWide
          className='center-xs'
          headerElem='h2'
          headerTitle={textTop.header}
          data-sal='slide-left'
        >
          <>
            {textTop.inner &&
              textTop.inner.map((text, i) => <p key={i}>{text}</p>)}
            {textTop.textWithLink && textTop.textWithLink()}
          </>
          <></>
        </CardWide>
        <Maze data={ventilatieData} path={path} gridImages={GridImages} />
      </Container>
    </Layout>
  );
};

Ventilatie.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Ventilatie;
